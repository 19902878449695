import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Outlet, useParams } from 'react-router-dom';
import http from '../http';
import { 
  Backdrop, 
  Button, 
  CircularProgress, 
  Container, 
  Paper, 
  useTheme, 
  useMediaQuery, 
  ThemeProvider, 
  createTheme, 
  Box, CssBaseline, 
  // AppBar, 
  Toolbar, 
  IconButton, 
  styled, 
  Divider,
  List,
  Grid,
  InputLabel,
  Typography,
  Collapse,
  Stack,
  Alert,
  AlertTitle
} from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import { Icon } from '@iconify/react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import './PDFViewer.css';
import useResizeObserver from 'use-resize-observer';
import IPropostaDto from '../interfaces/IPropostaDto';
import IAssinaturaDto from '../interfaces/IAssinaturaDto';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 340,
    width: `calc(100% - ${340}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 340,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

export default function PDFViewer() {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [successMessage, setSuccessMessage] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const { parametro } = useParams<{ parametro: string }>();
  const [loading, setLoading] = useState(false);
  const [binario, setBinario] = useState<any>(null);
  const [proposta, setProposta] = useState<IPropostaDto | null>(null);
  const [propostaCarregada, setPropostaCarregada] = useState(false);
  const [pdfCarregado, setPdfCarregado] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [assinatura, setAssinatura] = useState<IAssinaturaDto | null>(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  useEffect(() => {
    document.body.style.overflowY = 'auto';
    document.body.style.overflowX = 'hidden !important';

    setLoading(true);
    
    handlePdf();
    handleProposta();    
  }, []);

  useEffect(() => {
    if (pdfCarregado && propostaCarregada) {
      setLoading(false);
    }
  }, [pdfCarregado, propostaCarregada]);

  const handlePdf = () => {
    http
    .get(`/SignatureValidation?authentication=${encodeURIComponent(parametro!)}`, {
      responseType: 'arraybuffer'
    })
    .then((response) => {
      setPdfCarregado(true);
      setBinario(response.data);
    })
    .catch(() => {
      setLoading(false);
    });
  };

  const handleProposta = () => {
    http
      .get<IPropostaDto>(`/SignatureValidation/Proposal?authentication=${encodeURIComponent(parametro!)}`)
      .then((response) => {
        setPropostaCarregada(true);
        setProposta(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSign = () => {
    setOpenErrorMessage(false);
    setLoading(true);

    const data: IAssinaturaDto = {
      authentication: parametro!,
    };

    http
      .post(`/SignatureValidation`, data)
      .then((response) => {
        setProposta(prevProposta => ({ 
          ...prevProposta!, 
          // status: "Assinado", 
          assinatura: {
            ip: response.data.ip,
            dataHoraFormatada: response.data.dataHoraFormatada,
            status: "A",
          }
        }));
        
        setSuccessMessage(true);
        setLoading(false);
      })
      .catch(() => {
        setOpenErrorMessage(true);
        setLoading(false);
      });
  };

  const handleCloseErrorMessage = () => {
    setOpenErrorMessage(false);
  };

  const handleCloseSuccessMessage = () => {
    setSuccessMessage(false);
  };

  const handleClick = () => {
    if (openSignature)
      setOpenSignature(false)
    else
      setOpenSignature(true)
  }

  return (
    <>
      {(isSmScreen && !isMdScreen) && (
        <Grid container>
          <Grid 
            item 
            sm={12}
            xs={12}
            sx={{
              verticalAlign: 'middle'
            }}
          >
            <Toolbar
              sx={{
                pr: '24px',
                backgroundColor: '#1768e1',
                justifyContent: 'flex-end',  // Adiciona essa linha para alinhar à direita
                height: '64px',
                alignItems: 'self-end'
              }}
            >
              <IconButton
                edge="start"
                aria-label="open drawer"
                sx={{
                  color: '#fff',
                  alignSelf: 'stretch',
                }}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Grid>
        </Grid>
      )}
      {(isMdScreen) && (
        <Grid container>
          <Grid 
            item 
            sm={12}
            xs={12}
            sx={{
              verticalAlign: 'middle'
            }}
          >
            <Toolbar
              sx={{
                pr: '24px',
                backgroundColor: '#1768e1',
                justifyContent: 'flex-end',  // Adiciona essa linha para alinhar à direita
                height: '64px',
                alignItems: 'self-end'
              }}
            >
              <IconButton
                edge="start"
                aria-label="open drawer"
                sx={{
                  color: '#fff',
                  alignSelf: 'stretch',
                }}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid 
          item 
          sx={{ 
            display: 'flex', 
            // marginTop: (isSmScreen || isMdScreen) ? '0px' : '54px',
          }}
          xl={12}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <CssBaseline />
          {(openSignature) && (
            <Grid item sx={{ backgroundColor: '#f5f5f5' }}>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'hidden',
                  position: 'relative',  // Adicionado position: 'relative'
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  justifyContent: 'center', 
                }}
              >
                <Grid item>
                  <Toolbar
                    sx={{
                      backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      px: [1],borderRightColor: '#f5f5f5'
                    }}
                  >
                    <img
                      src={'./logo_transparent_background.png'}
                      style={{
                        maxWidth: (isSmScreen ? '100%' : '40%'),
                        height: 'auto',
                      }}
                    />
                  </Toolbar>
                </Grid>
                <Divider />
                <List 
                  sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'hidden',
                  }}
                  component="nav"
                >
                  {(propostaCarregada && pdfCarregado) && (
                    <Grid container>
                      <Grid 
                        item 
                        xs={12} 
                        sm={6}
                        xl={12}
                        sx={{
                          borderTopColor: '#1768e1',
                          color: '#e6eaee',
                          backgroundColor: 'white',
                          marginLeft: '12px',
                          marginRight: '12px',
                          paddingRight: '12px',
                          paddingLeft: '12px',
                          paddingTop: 0,
                          paddingBlock: '14px',
                          marginTop: '15px',
                          borderRadius: '6px',
                          borderBottom: '1px solid #e6eaee',
                          borderLeft: '1px solid #e6eaee',
                          borderTop: '1px solid #1768e1',
                          borderStyle: 'solid',
                          borderTopWidth: '6px',
                          borderRightWidth: '0px',
                          borderLeftWidth: '1px',
                          borderBottomWidth: '5px',
                          position: 'relative'
                        }}
                      >
                        <Grid container>
                          <Grid item>
                            <InputLabel sx={{
                                color: '#343a40',
                                fontSize: '.75em',
                                paddingRight: '10px'
                              }}
                            >Status:</InputLabel>
                          </Grid>
                          {proposta?.assinatura?.status === 'A' && (
                            <Grid item>
                              <InputLabel sx={{
                                  color: '#00c4a2',
                                  fontSize: '.75em',
                                  backgroundColor: '#e6eaee',
                                  lineHeight: '1em',
                                  borderRadius: '4px',
                                  padding: '2px 4px',
                                }}
                              >Assinado</InputLabel>
                            </Grid>
                          )}
                          {(proposta?.expirada && proposta?.assinatura?.status === 'P') && (
                            <Grid item>
                              <InputLabel sx={{
                                  color: '#e9055a',
                                  fontSize: '.75em',
                                  backgroundColor: '#e6eaee',
                                  lineHeight: '1em',
                                  borderRadius: '4px',
                                  padding: '2px 4px',
                                }}
                              >Expirado</InputLabel>
                            </Grid>
                          )}
                          {(proposta?.assinatura?.status === 'P' && !proposta?.expirada) && (
                            <Grid item>
                              <InputLabel sx={{
                                  color: '#1768e1',
                                  fontSize: '.75em',
                                  backgroundColor: '#e6eaee',
                                  lineHeight: '1em',
                                  borderRadius: '4px',
                                  padding: '2px 4px',
                                }}
                              >Pendente</InputLabel>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <InputLabel sx={{
                                color: '#343a40',
                                fontSize: '.75em',
                                paddingRight: '10px',
                                fontWeight: 'bold',
                              }}
                            >{proposta?.proponente!.nome}</InputLabel>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xl={1} sx={{
                            alignSelf: 'center',
                            color: '#868e96'
                          }}>
                            <Icon icon="mdi:id-card-outline" style={{ verticalAlign: 'text-top' }} />
                          </Grid>
                          <Grid item sx={{
                            alignSelf: 'center'
                          }}>
                            <InputLabel sx={{
                                color: '#343a40',
                                fontSize: '.75em',
                                paddingRight: '10px',
                                fontWeight: 'bold',
                              }}
                            >{proposta?.proponente!.cpfcnpj}</InputLabel>
                          </Grid>
                          {proposta?.proponente?.dataNascimentoFormatada != null && (
                            <>
                              <Grid item xl={1} sx={{
                                  alignSelf: 'center',
                                  color: '#868e96'
                                }}
                              >
                                <Icon icon="cil:calendar" style={{ verticalAlign: 'text-top' }} />
                              </Grid>
                              <Grid item sx={{
                                  alignSelf: 'center'
                                }}
                              >
                                <InputLabel sx={{
                                    color: '#343a40',
                                    fontSize: '.75em',
                                    paddingRight: '10px',
                                    fontWeight: 'bold',
                                    verticalAlign: 'center'
                                  }}
                                >{proposta.proponente.dataNascimentoFormatada}</InputLabel>
                              </Grid>
                            </>
                          )};
                        </Grid>
                        <Grid container>
                          <Grid item xl={1} sx={{
                              alignSelf: 'center',
                              color: '#868e96'
                            }}
                          >
                            <Icon icon="material-symbols:mail-outline" style={{ verticalAlign: 'text-top' }} />
                          </Grid>
                          <Grid item sx={{
                            alignSelf: 'center'
                          }}>
                            <InputLabel sx={{
                                color: '#343a40',
                                fontSize: '.75em',
                                paddingRight: '10px',
                                fontWeight: 'bold',
                              }}
                            >{proposta?.proponente!.emailCasa}</InputLabel>
                          </Grid>
                        </Grid>
                        {(proposta?.assinatura?.status === 'P' && !proposta?.expirada) && (
                          <Grid container>
                            <Grid item>
                              <Grid container>
                                <Grid item>
                                  <InputLabel sx={{
                                      color: '#e9055a',
                                      fontSize: '12px',
                                    }}
                                  >Esta proposta expira em {proposta?.dataExpiracaoFormatada}</InputLabel>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {(proposta?.assinatura?.status === 'P' && proposta?.expirada) && (
                          <Grid container>
                            <Grid item>
                              <Grid container>
                                <Grid item>
                                  <InputLabel sx={{
                                      color: '#e9055a',
                                      fontSize: '12px',
                                    }}
                                  >Esta proposta expirou em {proposta?.dataExpiracaoFormatada}</InputLabel>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {proposta?.assinatura?.status === 'A' && (
                          <Grid container>
                            <Grid item xl={1.7} sx={{
                              alignSelf: 'center',
                              color: '#868e96'
                            }}>
                              <Icon icon="material-symbols-light:check" style={{ 
                                verticalAlign: 'text-top', 
                                fontSize: '2em',
                                color: '#1768e1'
                              }} 
                              />
                            </Grid>
                            <Grid item>
                              <Grid container>
                                <Grid item>
                                  <InputLabel sx={{
                                      color: '#14112d',
                                      fontSize: '12px',
                                    }}
                                  >Assinou o documento</InputLabel>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item>
                                  <InputLabel sx={{
                                      color: '#959cae',
                                      fontSize: '11px',
                                    }}
                                  >{`Em ${proposta.assinatura.dataHoraFormatada} pelo IP ${proposta.assinatura.ip}`}</InputLabel>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </List>
              </Box>
            </Grid>
          )}
          {(!isSmScreen && !isMdScreen) && (
            <Drawer variant="permanent" open={open}
              sx={{
                width: '340px',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
              }}
            >
              <Toolbar
                sx={{
                  backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <img
                  src={'./logo_transparent_background.png'}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </Toolbar>
              <Divider />
              <List 
                sx={{
                  backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'hidden',
                }}
                component="nav"
              >
                {(propostaCarregada && pdfCarregado) && (
                  <Grid container>
                    <Grid 
                      item 
                      xs={12} 
                      sm={12}
                      xl={12}
                      sx={{
                        borderTopColor: '#1768e1',
                        color: '#e6eaee',
                        backgroundColor: 'white',
                        marginLeft: '12px',
                        marginRight: '12px',
                        paddingRight: '12px',
                        paddingLeft: '12px',
                        paddingTop: 0,
                        paddingBlock: '14px',
                        marginTop: '15px',
                        borderRadius: '6px',
                        borderBottom: '1px solid #e6eaee',
                        borderLeft: '1px solid #e6eaee',
                        borderTop: '1px solid #1768e1',
                        borderStyle: 'solid',
                        borderTopWidth: '6px',
                        borderRightWidth: '1px',
                        borderLeftWidth: '1px',
                        borderBottomWidth: '5px',
                        position: 'relative'
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <InputLabel sx={{
                              color: '#343a40',
                              fontSize: '.75em',
                              paddingRight: '10px'
                            }}
                          >Status:</InputLabel>
                        </Grid>
                        {proposta?.assinatura?.status === 'A' && (
                          <Grid item>
                            <InputLabel sx={{
                                color: '#00c4a2',
                                fontSize: '.75em',
                                backgroundColor: '#e6eaee',
                                lineHeight: '1em',
                                borderRadius: '4px',
                                padding: '2px 4px',
                              }}
                            >Assinado</InputLabel>
                          </Grid>
                        )}
                        {(proposta?.expirada && proposta?.assinatura?.status === 'P') && (
                          <Grid item>
                            <InputLabel sx={{
                                color: '#e9055a',
                                fontSize: '.75em',
                                backgroundColor: '#e6eaee',
                                lineHeight: '1em',
                                borderRadius: '4px',
                                padding: '2px 4px',
                              }}
                            >Expirado</InputLabel>
                          </Grid>
                        )}
                        {(proposta?.assinatura?.status === 'P' && !proposta?.expirada) && (
                          <Grid item>
                            <InputLabel sx={{
                                color: '#1768e1',
                                fontSize: '.75em',
                                backgroundColor: '#e6eaee',
                                lineHeight: '1em',
                                borderRadius: '4px',
                                padding: '2px 4px',
                              }}
                            >Pendente</InputLabel>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <InputLabel sx={{
                              color: '#343a40',
                              fontSize: '.75em',
                              paddingRight: '10px',
                              fontWeight: 'bold',
                            }}
                          >{proposta?.proponente!.nome}</InputLabel>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xl={1} sx={{
                          alignSelf: 'center',
                          color: '#868e96'
                        }}>
                          <Icon icon="mdi:id-card-outline" style={{ verticalAlign: 'text-top' }} />
                        </Grid>
                        <Grid item sx={{
                          alignSelf: 'center'
                        }}>
                          <InputLabel sx={{
                              color: '#343a40',
                              fontSize: '.75em',
                              paddingRight: '10px',
                              fontWeight: 'bold',
                            }}
                          >{proposta?.proponente!.cpfcnpj}</InputLabel>
                        </Grid>
                        {proposta?.proponente?.dataNascimentoFormatada != null && (
                          <>
                            <Grid item xl={1} sx={{
                                alignSelf: 'center',
                                color: '#868e96'
                              }}
                            >
                              <Icon icon="cil:calendar" style={{ verticalAlign: 'text-top' }} />
                            </Grid>
                            <Grid item sx={{
                                alignSelf: 'center'
                              }}
                            >
                              <InputLabel sx={{
                                  color: '#343a40',
                                  fontSize: '.75em',
                                  paddingRight: '10px',
                                  fontWeight: 'bold',
                                  verticalAlign: 'center'
                                }}
                              >{proposta.proponente.dataNascimentoFormatada}</InputLabel>
                            </Grid>
                          </>
                        )};
                      </Grid>
                      <Grid container>
                        <Grid item xl={1} sx={{
                            alignSelf: 'center',
                            color: '#868e96'
                          }}
                        >
                          <Icon icon="material-symbols:mail-outline" style={{ verticalAlign: 'text-top' }} />
                        </Grid>
                        <Grid item sx={{
                          alignSelf: 'center'
                        }}>
                          <InputLabel sx={{
                              color: '#343a40',
                              fontSize: '.75em',
                              paddingRight: '10px',
                              fontWeight: 'bold',
                            }}
                          >{proposta?.proponente!.emailCasa}</InputLabel>
                        </Grid>
                      </Grid>
                      {(proposta?.assinatura?.status === 'P' && !proposta?.expirada) && (
                        <Grid container>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <InputLabel sx={{
                                    color: '#e9055a',
                                    fontSize: '12px',
                                  }}
                                >Esta proposta expira em {proposta?.dataExpiracaoFormatada}</InputLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {(proposta?.assinatura?.status === 'P' && proposta?.expirada) && (
                        <Grid container>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <InputLabel sx={{
                                    color: '#e9055a',
                                    fontSize: '12px',
                                  }}
                                >Esta proposta expirou em {proposta?.dataExpiracaoFormatada}</InputLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {proposta?.assinatura?.status === 'A' && (
                        <Grid container>
                          <Grid item xl={1.7} sx={{
                            alignSelf: 'center',
                            color: '#868e96'
                          }}>
                            <Icon icon="material-symbols-light:check" style={{ 
                              verticalAlign: 'text-top', 
                              fontSize: '2em',
                              color: '#1768e1'
                            }} 
                            />
                          </Grid>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <InputLabel sx={{
                                    color: '#14112d',
                                    fontSize: '12px',
                                  }}
                                >Assinou o documento</InputLabel>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item>
                                <InputLabel sx={{
                                    color: '#959cae',
                                    fontSize: '11px',
                                  }}
                                  >{`Em ${proposta.assinatura.dataHoraFormatada} pelo IP ${proposta.assinatura.ip}`}</InputLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </List>
            </Drawer>
          )}
          {(!openSignature && !isSmScreen && isMdScreen) && (
            <Grid component="main">
              <Grid container>
                <Grid item xl={1}></Grid>
                <Grid item xl={10} sx={{ alignItems: 'center' }}>
                  <Collapse in={successMessage}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="success" onClose={handleCloseSuccessMessage}>
                        <AlertTitle>Proposta assinada com sucesso!</AlertTitle>
                      </Alert>
                    </Stack>
                  </Collapse>
                  <Collapse in={openErrorMessage}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="error" onClose={handleCloseErrorMessage}>
                        <AlertTitle>Ocorreu um erro ao efetuar a assinatura!</AlertTitle>
                        Por favor, tente novamente mais tarde.
                      </Alert>
                    </Stack>
                  </Collapse>
                  <Collapse in={proposta?.expirada}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="warning">
                        <AlertTitle>A proposta expirou, favor entrar em contato com o vendedor.</AlertTitle>
                      </Alert>
                    </Stack>
                  </Collapse>
                  <Grid item xl={1}></Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12} sx={{ textAlign: 'center', overflow: 'auto' }}>
                  <img
                    src={'./logo.jpeg'}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ paddingLeft: '0px' }}>
                  <Container sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'start', 
                  }}>
                    {binario &&
                      <Document file={binario} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        {Array.from(new Array(numPages), (el, index) => (
                          <Paper key={`page_${index + 1}`} sx={{ 
                            padding: theme.spacing(2), 
                            marginBottom: theme.spacing(2), 
                            height: '100%', 
                            display: 'flex', 
                            flexDirection: 'column',  // Adicionado flexDirection: 'column'
                            alignItems: 'center',
                          }}>
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              width={isSmScreen ? 300 : 780}
                              renderTextLayer={false}
                              sx={{ flex: 1 }}  // Adicionado flex: 1
                            />
                          </Paper>
                        ))}
                      </Document>
                    }
                    {(!proposta?.expirada && proposta?.assinatura?.status === 'P' && propostaCarregada && pdfCarregado) && (
                      <Button 
                        variant="contained" 
                        onClick={handleSign}
                        color="primary" 
                        sx={{
                          position: 'fixed',
                          bottom: theme.spacing(2),
                          // left: '50%',
                          transform: 'translateX(-50%)',
                          background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                          '&:hover': {
                            background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                          }}}
                      >
                        Assinar
                      </Button>
                    )}
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
          {(!openSignature && isSmScreen && isMdScreen && isLgScreen) && (
            <Grid component="main">
              <Grid
                item
                xl={12}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  mt: 4,
                  mb: 4,
                  marginLeft: '0px',
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid 
                  item 
                  xl={12}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Grid container>
                    <Grid item xl={1}></Grid>
                    <Grid item xl={10} sx={{ alignItems: 'center' }}>
                      <Collapse in={successMessage}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="success" onClose={handleCloseSuccessMessage}>
                            <AlertTitle>Proposta assinada com sucesso!</AlertTitle>
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Collapse in={openErrorMessage}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="error" onClose={handleCloseErrorMessage}>
                            <AlertTitle>Ocorreu um erro ao efetuar a assinatura!</AlertTitle>
                            Por favor, tente novamente mais tarde.
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Collapse in={proposta?.expirada}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="warning">
                            <AlertTitle>A proposta expirou, favor entrar em contato com o vendedor.</AlertTitle>
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Grid item xl={1}></Grid>
                    </Grid>
                  </Grid>
                  <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '100vh' }}>
                    <div style={{ width: isSmScreen ? '100%' : 800 }}>
                      <img
                        src={'./logo.jpeg'}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                      {binario &&
                        Array.from(new Array(numPages), (el, index) => (
                          <Paper key={`page_${index + 1}`} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: '-webkit-center' }}>
                            <Document file={binario} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                              <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={isSmScreen ? 300 : 780}
                                renderTextLayer={false}
                                sx={{ display: 'block', marginBottom: '20px' }}
                                // sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              />
                            </Document>
                        
                          </Paper>
                        ))}
                        {(!proposta?.expirada && proposta?.assinatura?.status === 'P' && propostaCarregada && pdfCarregado) && (
                          <Button 
                            variant="contained" 
                            onClick={handleSign}
                            color="primary" 
                            sx={{
                              position: 'sticky',
                              bottom: isSmScreen ? theme.spacing(2) : (isMdScreen ? theme.spacing(6) : theme.spacing(2)),
                              left: '50%',
                              transform: 'translateX(-50%)',
                              zIndex: 1,
                              background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                              '&:hover': {
                                background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                              },
                            }}
                          >
                            Assinar
                          </Button>
                        )}
                    </div>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
          {(!isSmScreen && !isMdScreen && !isLgScreen) && (
            <Grid component="main">
              <Grid
                item
                xl={12}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  mt: 4,
                  mb: 4,
                  marginLeft: '0px',
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid 
                  item 
                  xl={12}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Grid container>
                    <Grid item xl={1}></Grid>
                    <Grid item xl={10} sx={{ alignItems: 'center' }}>
                      <Collapse in={successMessage}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="success" onClose={handleCloseSuccessMessage}>
                            <AlertTitle>Proposta assinada com sucesso!</AlertTitle>
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Collapse in={openErrorMessage}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="error" onClose={handleCloseErrorMessage}>
                            <AlertTitle>Ocorreu um erro ao efetuar a assinatura!</AlertTitle>
                            Por favor, tente novamente mais tarde.
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Collapse in={proposta?.expirada}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="warning">
                            <AlertTitle>A proposta expirou, favor entrar em contato com o vendedor.</AlertTitle>
                          </Alert>
                        </Stack>
                      </Collapse>
                      <Grid item xl={1}></Grid>
                    </Grid>
                  </Grid>
                  <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '100vh' }}>
                    <div style={{ width: isSmScreen ? '100%' : 800, textAlign: 'center' }}>
                      <img
                        src={'./logo.jpeg'}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                      {binario &&
                        Array.from(new Array(numPages), (el, index) => (
                          <Paper key={`page_${index + 1}`} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: '-webkit-center' }}>
                            <Document file={binario} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                              <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={isSmScreen ? 300 : 780}
                                renderTextLayer={false}
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              />
                            </Document>
                        
                          </Paper>
                        ))}
                        {(!proposta?.expirada && proposta?.assinatura?.status === 'P' && propostaCarregada && pdfCarregado) && (
                          <Button 
                            variant="contained" 
                            onClick={handleSign}
                            color="primary" 
                            sx={{
                              position: 'sticky',
                              bottom: isSmScreen ? theme.spacing(2) : (isMdScreen ? theme.spacing(6) : theme.spacing(2)),
                              // left: '50%',
                              transform: 'translateX(-50%)',
                              zIndex: 1,
                              background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                              '&:hover': {
                                background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                              },
                            }}
                          >
                            Assinar
                          </Button>
                        )}
                    </div>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
